/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Convert scripts
 */
export class Scripts {
    static getScript() {
        return `var _conv_page_type = "";
              var _conv_category_id = "";
              var _conv_category_name = "";
              var _conv_product_sku = "";
              var _conv_product_name = "";
              var _conv_product_price = "";
              var _conv_customer_id = "";
              var _conv_custom_v1 = "";
              var _conv_custom_v2 = "";
              var _conv_custom_v3 = "";
              var _conv_custom_v4 = "";`;
    }
}

export default Scripts;
