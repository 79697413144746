/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * PINTEREST scripts
 */
export class Scripts {
    static getScript({
        id
    }) {
        return `!function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
                Array.prototype.slice.call(arguments))};var  n=window.pintrk;n.queue=[],n.version="3.0";var
                t=document.createElement("script");t.async=!0,t.src=e;var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                window.pintrk.partnerData={"np":"adobe"};
                pintrk("load", '${id}', {});
                pintrk("page");`
    }
}

export default Scripts;
