/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_PINTEREST_CHECKOUT = 'pinterest_checkout';
export const EVENT_PINTEREST_CHECKOUT_OPTION = 'pinterest_checkout_option';
export const EVENT_PINTEREST_IMPRESSIONS = 'pinterest_impressions';
export const EVENT_PINTEREST_IMPRESSIONS_PLP = 'pinterest_impressions_plp';
export const EVENT_PINTEREST_IMPRESSIONS_HOME = 'pinterest_impressions_home';
export const EVENT_PINTEREST_IMPRESSIONS_CROSS_SELL = 'pinterest_impressions_cross_sell';
export const EVENT_PINTEREST_IMPRESSIONS_WISHLIST = 'pinterest_impressions_wishlist';
export const EVENT_PINTEREST_IMPRESSIONS_SEARCH = 'pinterest_impressions_search';
export const EVENT_PINTEREST_IMPRESSIONS_LINKED = 'pinterest_impressions_linked';
export const EVENT_PINTEREST_META_UPDATE = 'pinterest_meta_update';
export const EVENT_PINTEREST_GENERAL_INIT = 'pinterest_general_init';
export const EVENT_PINTEREST_PRODUCT_ADD_TO_CART = 'pinterest_product_add_to_cart';
export const EVENT_PINTEREST_PRODUCT_CLICK = 'pinterest_product_click';
export const EVENT_PINTEREST_PRODUCT_DETAIL = 'pinterest_product_detail';
export const EVENT_PINTEREST_PRODUCT_REMOVE_FROM_CART = 'pinterest_product_remove_from_cart';
export const EVENT_PINTEREST_PURCHASE = 'pinterest_purchase';
export const EVENT_PINTEREST_USER_LOGIN = 'pinterest_user_login';
export const EVENT_PINTEREST_USER_REGISTER = 'pinterest_user_register';
