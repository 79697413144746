/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_CONVERT_CHECKOUT = 'convert_checkout';
export const EVENT_CONVERT_CHECKOUT_OPTION = 'convert_checkout_option';
export const EVENT_CONVERT_IMPRESSIONS = 'convert_impressions';
export const EVENT_CONVERT_IMPRESSIONS_PLP = 'convert_impressions_plp';
export const EVENT_CONVERT_IMPRESSIONS_HOME = 'convert_impressions_home';
export const EVENT_CONVERT_IMPRESSIONS_CROSS_SELL = 'convert_impressions_cross_sell';
export const EVENT_CONVERT_IMPRESSIONS_WISHLIST = 'convert_impressions_wishlist';
export const EVENT_CONVERT_IMPRESSIONS_SEARCH = 'convert_impressions_search';
export const EVENT_CONVERT_IMPRESSIONS_LINKED = 'convert_impressions_linked';
export const EVENT_CONVERT_META_UPDATE = 'convert_meta_update';
export const EVENT_CONVERT_GENERAL_INIT = 'convert_general_init';
export const EVENT_CONVERT_PRODUCT_ADD_TO_CART = 'convert_product_add_to_cart';
export const EVENT_CONVERT_PRODUCT_CLICK = 'convert_product_click';
export const EVENT_CONVERT_PRODUCT_DETAIL = 'convert_product_detail';
export const EVENT_CONVERT_PRODUCT_REMOVE_FROM_CART = 'convert_product_remove_from_cart';
export const EVENT_CONVERT_PURCHASE = 'convert_purchase';
export const EVENT_CONVERT_CART = 'convert_cart';
export const EVENT_CONVERT_USER_LOGIN = 'convert_user_login';
export const EVENT_CONVERT_USER_REGISTER = 'convert_user_register';
export const EVENT_CONVERT_CMS_PAGE = 'convert_cms';
export const EVENT_CONVERT_HOMEPAGE = 'convert_homepage';
