import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import ProductListContentPush from 'Component/ProductListContentPush';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductListPage as SourceProductListPage
} from 'SourceComponent/ProductListPage/ProductListPage.component';
import { CategoryContentPushType } from 'Type/Category.type';

import './ProductListPage.override.style';

/** @namespace PeggysagePwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPage {
    static propTypes = {
        ...SourceProductListPage.propTypes,
        contentPushes: PropTypes.arrayOf(CategoryContentPushType),
        isWidget: PropTypes.bool,
        categoryId: PropTypes.number
    };

    static defaultProps = {
        ...SourceProductListPage.defaultProps,
        contentPushes: [],
        isWidget: false,
        categoryId: null
    };

    state = {
        ...this.state,
        isWidgetExpanded: false
    };

    onShowMoreClick = this.onShowMoreClick.bind(this);

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onShowMoreClick() {
        const { isWidgetExpanded } = this.state;

        this.setState({ isWidgetExpanded: !isWidgetExpanded });
    }

    renderShowMore() {
        const { items, isWidget } = this.props;
        const { isWidgetExpanded } = this.state;

        // eslint-disable-next-line no-magic-numbers
        if (!isWidget || items.length <= 3) {
            return null;
        }

        const label = isWidgetExpanded ? __('See less') : __('See more');

        return (
            <div block="ProductListPage" elem="ShowMore">
                <button
                  type="button"
                  block="Button"
                  mods={ { likeLink: true, isWidgetExpanded } }
                  mix={ { block: 'ProductListPage', elem: 'ShowMoreLink' } }
                  onClick={ this.onShowMoreClick }
                >
                    <span>{ label }</span>
                </button>
            </div>
        );
    }

    /**
     * Override: prevent update page on scroll
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    startObserving() {
        // ...
    }

    /**
     * Override: add pushes
     * @returns {unknown[]}
     */
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            },
            contentPushes
        } = this.props;

        const itemsWithPushes = Array.from(items);

        // Add pushes to items array at the defined position
        if (contentPushes?.length) {
            contentPushes.forEach((push) => {
                const { position } = push;
                const start = +position - 1;
                itemsWithPushes.splice(start < 0 ? 0 : start, 0, push);
            });
        }

        return itemsWithPushes.map((item, i) => {
            if (!item.sku) {
                return (
                    <ProductListContentPush
                      contentPush={ item }
                      // eslint-disable-next-line react/no-array-index-key
                      key={ i }
                    />
                );
            }

            return (
                <ProductCard
                  product={ item }
                  // eslint-disable-next-line react/no-array-index-key
                  key={ i }
                  selectedFilters={ selectedFilters }
                  layout={ layout }
                  { ...this.containerProps() }
                />
            );
        });
    }

    /**
     * Override: add show more link for widgets
     * @returns {JSX.Element}
     */
    render() {
        const {
            pageNumber,
            wrapperRef,
            mix,
            isWidget,
            categoryId
        } = this.props;
        const { isWidgetExpanded } = this.state;

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              mods={ { isWidget, isWidgetExpanded } }
              key={ pageNumber }
              ref={ wrapperRef }
              categoryId={ categoryId }
            >
                { this.renderItems() }
                { this.renderShowMore() }
            </ul>
        );
    }
}

export default withRouter(ProductListPageComponent);
