/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import { GROUPED_PRODUCTS_GUEST } from '../component/Pinterest/Pinterest.config';
import PinterestContainer from '../component/Pinterest/Pinterest.container';
import ProductHelper from '../component/Pinterest/utils/Product';
import PinterestQuery from '../query/Pinterest.query';

export const handle_syncCartWithBEError = (args, callback) => callback(...args)
    .then(
        (result) => {
            PinterestContainer.getInstance().setGroupedProducts({});
            return result;
        }
    );

export const addPinterestConfigQuery = (args, callback) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        PinterestQuery.getPINTERESTConfiguration()
    ];
};

export const addPinterestToConfigReducerInitialState = (args, callback) => {
    const { pinterest } = BrowserDatabase.getItem('config') || { pinterest: {} };

    return {
        ...callback(...args),
        pinterest
    };
};

export const addPinterestToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { pinterest } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        pinterest
    };
};

export const afterRequestCustomerData = (args, callback) => {
    const pinterest = PinterestContainer.getInstance();

    /** transfer grouped products data from guest to logged in user */
    const transferGroupedProductsData = (id) => {
        if (!pinterest || pinterest.groupedProductsStorageName !== GROUPED_PRODUCTS_GUEST) {
            return;
        }


        const guestGroupedProducts = pinterest.getGroupedProducts();
        pinterest.setGroupedProducts({});
        pinterest.updateGroupedProductsStorageName(id);

        const userGroupedProducts = pinterest.getGroupedProducts();
        const result = ProductHelper.mergeGroupedProducts(guestGroupedProducts, userGroupedProducts);

        pinterest.setGroupedProducts(result);
    };

    return callback(...args)
        .then((result) => {
            if (pinterest) {
                let customer = BrowserDatabase.getItem('customer');
                transferGroupedProductsData(customer.id);
                pinterest.updateGroupedProductsStorageName(customer.id);
            }

            return result;
        });
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            handle_syncCartWithBEError
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addPinterestConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addPinterestToConfigReducerInitialState
    },
    'PeggysagePwa/Store/Config/Reducer/ConfigReducer': {
        function: addPinterestToConfigUpdate
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData: afterRequestCustomerData
        }
    }
};
