/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_PINTEREST_PRODUCT_ADD_TO_CART } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import PinterestQuery from '../../../query/Pinterest.query';
import { fetchQuery } from 'Util/Request/Query';

export const SPAM_PROTECTION_DELAY = 200;
/**
 * Product add to cart event
 */
export class AddToCartEvent extends BaseEvent {
    /**
     * Bind add to cart
     */
    bindEvent() {
        Event.observer(EVENT_PINTEREST_PRODUCT_ADD_TO_CART, ({
            product,
            quantity,
            configurableVariantIndex,
            massAddAction = false,
            isFromCart = false,
            isGrouped = false,
            isItem = false
        }) => {
            this.handle(
                { configurableVariantIndex, ...product },
                quantity || 1,
                isItem,
                isGrouped,
                isFromCart,
                massAddAction
            );
        });
    }

    /**
     * Handle product add to cart
     */
    handler(product, quantity, isItem, isGrouped, isFromCart, massAddAction) {
        if (!massAddAction && this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const event = {
            'event_name':  'add_to_cart'
        };

        fetchQuery(PinterestQuery.getPINTERESTConversion(event)).then(
            (result) => {
                if (result && result.getPinterestConversionEvent && result.getPinterestConversionEvent.event_id) {
                    try {
                        pintrk('setconsent', true);
                        pintrk('track', 'AddToCart', {
                            event_id: result.getPinterestConversionEvent.event_id,
                            line_items: result.getPinterestConversionEvent.line_items,
                            currency: result.getPinterestConversionEvent.currency,
                            value: result.getPinterestConversionEvent.value

                        });
                    } catch (e){
                        console.log('pinterestevent', 'error', e);
                    }
                }
            }
        )
        .catch(
            (error) => {
                console.log('pinterestevent', 'error', error);
            }
        );
    }
}

export default AddToCartEvent;
