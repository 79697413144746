/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class PinterestQuery {
    _getPINTERESTConfigurationFields = () => ([
        'enabled',
        'pinterest_id',
        'ldp_enabled',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'pinterest_general_init',
        'pinterest_impressions',
        'pinterest_product_detail',
        'pinterest_product_add_to_cart',
        'pinterest_purchase',
        'pinterest_search'
    ]);

    _getPINTERESTConversionEventFields = () => ([
        'event_id',
        'event_name',
        'search_query',
        'order_quantity',
        'currency',
        'value',
        'content_ids',
        'category',
        'num_items',
        'order_id',
        'increment_id',
        this.getLineItems(),
        this.getLineItemsCategory(),
        this.Contents()
    ]);

    Contents = () => new Field('contents').addFieldList(this.getContentsFields());
    getLineItems = () => new Field('line_items').addFieldList(this.getLineItemsFields());
    getLineItemsCategory = () => new Field('line_items_category').addFieldList(this.getLineItemsFields());
    getContentsFields = () => ([
        'item_price',
        'quantity'

    ]);
    getLineItemsFields = () => ([
        'product_id',
        'product_price',
        'product_name',
        'product_quantity',
        'product_category'
    ]);

    getPINTERESTConfiguration = () => new Field('getPinterest')
        .setAlias('pinterest')
        .addFieldList(this._getPINTERESTConfigurationFields());

    getPINTERESTConversion = (event) => new Field('getPinterestConversionEvent')
        .addArgument('event', 'PinterestConversionEventInput!', event)
        .addFieldList(this._getPINTERESTConversionEventFields());
}

export default new PinterestQuery();
