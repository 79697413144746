import {
    defaultConfig,
    getInitialState
} from 'SourceStore/ProductList/ProductList.reducer';
import { getIndexedProducts } from 'Util/Product';

import {
    APPEND_PAGE,
    RESET_PRODUCT_LIST_ITEMS,
    UPDATE_LOAD_STATUS,
    UPDATE_PAGE_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_ITEMS
} from './ProductList.action';

export {
    defaultConfig,
    getInitialState
};

/** @namespace PeggysagePwa/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        isLoading,
        args: currentArgs
    } = action;

    switch (type) {
    case APPEND_PAGE:
        return {
            ...state,
            isPageLoading: false,
            pages: {
                ...state.pages,
                [currentPage]: getIndexedProducts(initialItems)
            }
        };

    case UPDATE_PRODUCT_LIST_ITEMS:
        return {
            ...state,
            currentArgs,
            isLoading: typeof currentPage === 'undefined',
            isPageLoading: typeof currentPage === 'undefined',
            totalItems,
            totalPages,
            pages: { ...(state.pages || {}), [currentPage]: getIndexedProducts(initialItems) }
        };

    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };

    case UPDATE_LOAD_STATUS:
        return {
            ...state,
            isLoading: isLoading || typeof currentPage === 'undefined'
        };

    case RESET_PRODUCT_LIST_ITEMS:
        return getInitialState();

    default:
        return state;
    }
};

export default ProductListReducer;
