/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, {
    EVENT_PINTEREST_GENERAL_INIT,
    EVENT_PINTEREST_IMPRESSIONS_CROSS_SELL,
    EVENT_PINTEREST_IMPRESSIONS_HOME,
    EVENT_PINTEREST_IMPRESSIONS_LINKED,
    EVENT_PINTEREST_IMPRESSIONS_PLP,
    EVENT_PINTEREST_IMPRESSIONS_SEARCH,
    EVENT_PINTEREST_IMPRESSIONS_WISHLIST
} from '../../../util/Event';
import { getCurrentVariantIndexFromFilters } from '../../../util/Product';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import PinterestQuery from '../../../query/Pinterest.query';
import { fetchQuery } from 'Util/Request/Query';

/**
 * Website places, from where was received event data
 *
 * @type {string}
 */
export const PLP_IMPRESSIONS = 'catalog';
export const HOME_IMPRESSIONS = 'home';
export const WISHLIST_IMPRESSIONS = 'wishlist';
export const CHECKOUT_CROSS_SELL_IMPRESSIONS = 'checkout_cross_sell';
export const SEARCH_IMPRESSIONS = 'search';
export const RECOMMENDED_IMPRESSIONS = 'recommended';

/**
 * Constants
 *
 * @type {number}
 */
export const SPAM_PROTECTION_DELAY = 2000;
export const PRODUCT_IMPRESSION_COUNT = 36;
export const PRODUCT_IMPRESSION_CHUNK_SIZE = 12;
export const EVENT_HANDLE_DELAY = 700;

/**
 * PINTEREST PWA Impression Event
 *
 * Called when customer see product list
 * On: Home, PLP, WishList, Up-Sell, Cross-Sell, Accessories(related things on PDP)
 */
export class Impression extends BaseEvent {
    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = EVENT_HANDLE_DELAY;

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        // PLP
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_PLP, ({ items, filters, categoryId }) => {
            this.handle(PLP_IMPRESSIONS, items, filters, categoryId, null);
        });

        // Home
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_HOME, ({ items, filters }) => {
            this.handle(HOME_IMPRESSIONS, items, filters);
        });

        // Checkout Cross-sell
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_CROSS_SELL, ({ items }) => {
            this.handle(CHECKOUT_CROSS_SELL_IMPRESSIONS, items);
        });

        // Wishlist
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_WISHLIST, ({ items }) => {
            this.handle(WISHLIST_IMPRESSIONS, items);
        });

        // Search
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_SEARCH, ({ items, filters, search }) => {
            this.handle(SEARCH_IMPRESSIONS, items, filters, null, search);
        });

        // Recommended
        Event.observer(EVENT_PINTEREST_IMPRESSIONS_LINKED, ({ items }) => {
            this.handle(RECOMMENDED_IMPRESSIONS, items);
        });

        // General
        Event.observer(EVENT_PINTEREST_GENERAL_INIT, () => {
            this.cleanStorage();
        });
    }

    /**
     * Handle Impressions
     *
     * @param productCollectionType product event type
     * @param products Product list
     * @param filters Category filters
     */
    handler(productCollectionType = PLP_IMPRESSIONS, products = [], filters = {}, category = null, search = null) {
        if (productCollectionType !== PLP_IMPRESSIONS && productCollectionType !== SEARCH_IMPRESSIONS){
            return;
        }
        const impressions = this.getImpressions(productCollectionType, products, filters, category);
        const storage = this.getStorage();
        const impressionUID = this.getImpressionUID(impressions);

        if (!impressions
            || Object.values(impressions).length === 0
            || this.spamProtection(SPAM_PROTECTION_DELAY, `${ productCollectionType }_${ impressionUID }`)
        ) {
            return;
        }

        if (!storage.impressions) {
            storage.impressions = [];
        }
        storage.impressions.push(...impressions);
        this.setStorage(storage);

        const productsIdsArray = impressions.map((product) => product.product_id);
        const event = search ? {
                'event_name': 'search',
                'search_query': search
            } : {
            'event_name':  'view_category',
            'product_details': {
                'content_ids': productsIdsArray,
                'category': category,
            }
        };

        fetchQuery(PinterestQuery.getPINTERESTConversion(event)).then(
            (result) => {
                if (result && result.getPinterestConversionEvent && result.getPinterestConversionEvent.event_id) {
                    try {
                        if (result.getPinterestConversionEvent.search_query){
                            pintrk('track', 'search', {
                                event_id: result.getPinterestConversionEvent.event_id,
                                search_query: result.getPinterestConversionEvent.search_query
                            });
                        } else {
                            pintrk('track', 'viewcategory', {
                                event_id: result.getPinterestConversionEvent.event_id,
                                line_items: result.getPinterestConversionEvent.line_items_category,
                                currency: result.getPinterestConversionEvent.currency

                            });
                        }
                    } catch (e){
                        console.log('pinterestevent', 'error', e);
                    }
                }
            }
        )
        .catch(
            (error) => {
                console.log('pinterestevent', 'error', error);
            }
        );
    }

    /**
     * Get impressions
     *
     * @param productCollectionType
     * @param products
     * @param filters
     *
     * @return {{price: string, name: string, variant: string, id: string, position: number, list: string, category: string, brand: string}[]}
     */
    getImpressions(productCollectionType = PLP_IMPRESSIONS, products, filters, category) {
        const productCollection = this.getProductCollection(productCollectionType, products);
        const productCount = Object.values(productCollection || []).length;

        const offset = PRODUCT_IMPRESSION_COUNT - productCount < 0
            ? Math.abs(PRODUCT_IMPRESSION_COUNT - productCount)
            : 0;

        return Object.values(productCollection || [])
            .slice(-PRODUCT_IMPRESSION_COUNT) // Last from list
            .filter((product) => Object.values(product).length)
            .map((product, index) => {
                const configurableVariantIndex = 0;

                const productData = productCollectionType === WISHLIST_IMPRESSIONS
                    ? ProductHelper.getItemData(product)
                    : ProductHelper.getProductData(product);

                return {
                    ...productData,
                    position: offset + index + 1,
                    list: this.getProductCollectionList(productCollectionType, product, category)
                };
            });
    }

    /**
     * Get collection of products
     *
     * @param productCollectionType
     * @param params
     *
     * @return {Array}
     */
    getProductCollection(productCollectionType = PLP_IMPRESSIONS, products) {
        switch (productCollectionType) {
        case PLP_IMPRESSIONS:
        case WISHLIST_IMPRESSIONS:
        case HOME_IMPRESSIONS:
        case SEARCH_IMPRESSIONS:
        case RECOMMENDED_IMPRESSIONS:
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return products || [];
        default:
            return [];
        }
    }

    /**
     * Get product collection list name
     *
     * @param productCollectionType
     * @param product
     *
     * @return {string}
     */
    getProductCollectionList(productCollectionType = PLP_IMPRESSIONS, product, categoryName = '') {
        switch (productCollectionType) {
        case HOME_IMPRESSIONS:
            return 'Homepage';
        case RECOMMENDED_IMPRESSIONS:
            return 'Recommended';
        case SEARCH_IMPRESSIONS:
            return 'Search results';
        case WISHLIST_IMPRESSIONS:
            return 'Wishlist';
        case CHECKOUT_CROSS_SELL_IMPRESSIONS:
            return 'Cross sell impressions';
        case PLP_IMPRESSIONS:
            return categoryName
                ? `PLP - ${ categoryName }`
                : 'PLP';
        default:
            return ProductHelper.getList(product);
        }
    }

    /**
     * Get provided impression UID
     *
     * @param impression
     * @return {string}
     */
    getImpressionUID(impression = []) {
        return impression.reduce((acc, { product_id }) => `${ acc }_${ product_id }`, '');
    }
}

export default Impression;
