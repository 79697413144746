/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_PINTEREST_PRODUCT_DETAIL } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import PinterestQuery from '../../../query/Pinterest.query';
import { fetchQuery } from 'Util/Request/Query';

export const SPAM_PROTECTION_TIMEOUT = 500;
export const EVENT_EXECUTION_DELAY = 200;

/**
 * Product detail push event
 */
export class ProductDetail extends BaseEvent {
    /**
     * Last product path name
     *
     * @type {null|string}
     */
    lastPath = null;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_PINTEREST_PRODUCT_DETAIL, ({ product, pathname }) => {
            setTimeout(() => {
                this.handle(product, pathname);
            }, EVENT_EXECUTION_DELAY);
        });

        this.lastPath = null;
    }

    /**
     * Handle product detail event
     *
     * @param product
     * @param pathname
     */
    handler(product, pathname) {
        const { sku, type_id, id } = product;

        if (this.spamProtection(SPAM_PROTECTION_TIMEOUT, sku)
            || pathname === this.lastPath
        ) {
            return;
        }

        this.lastPath = pathname;

        const productToPass = type_id === 'simple'
            ? ProductHelper.getProductData(product, true)
            : ProductHelper.getProductData(product);

        const event = {
            'event_name':  'page_visit',
            'product_data': {
                'product_id': id
            }
        };


        fetchQuery(PinterestQuery.getPINTERESTConversion(event)).then(
            (result) => {
                if (result && result.getPinterestConversionEvent && result.getPinterestConversionEvent.event_id) {
                    try {
                        pintrk('track', 'pagevisit', {
                            event_id: result.getPinterestConversionEvent.event_id,
                            line_items: result.getPinterestConversionEvent.line_items,
                            order_quantity: 0,
                            currency: result.getPinterestConversionEvent.currency

                        });
                    } catch (e){
                        console.log(e);
                    }
                }
            }
        )
        .catch(
            (error) => {
               console.log('pinterestevent', 'error', error);
            }
        );
    }
}

export default ProductDetail;
