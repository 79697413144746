import PropTypes from 'prop-types';

import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';
import { isSignedIn } from 'Util/Auth';

/** @namespace PeggysagePwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    static propTypes = {
        ...SourceMeta.propTypes,
        openGraphMetadata: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })
        ).isRequired,
        hrefLang: PropTypes.arrayOf(
            PropTypes.shape({
                store_code: PropTypes.string.isRequired,
                store_id: PropTypes.number.isRequired,
                store_url: PropTypes.string,
                full_path: PropTypes.string,
                absolute_url: PropTypes.string
            })
        )
    };

    renderOpenGraphMeta() {
        const { openGraphMetadata } = this.props;

        return openGraphMetadata.map((tag) => {
            const {
                property = null,
                content = null
            } = tag;

            return (
                <meta
                  key={ property }
                  property={ property }
                  content={ content }
                />
            );
        });
    }

    renderHrefLang() {
        if (isSignedIn()) {
            return null;
        }

        const { hrefLang } = this.props;
        if (hrefLang && hrefLang.length) {
            return hrefLang.map((href, _index) => <link rel="alternate" key={ href.store_code } hrefLang={ href.store_code } href={ href.absolute_url } />);
        }

        return (
            <link rel="alternate" hreflang="fr-FR" href={ location.href } />
        );
    }

    /**
     * Override: add open graph meta
     * @returns {JSX.Element}
     */
    renderMeta() {
        return (
            <>
                { super.renderMeta() }
                { this.renderHrefLang() }
                { this.renderOpenGraphMeta() }
            </>
        );
    }
}

export default MetaComponent;
