/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_CONVERT_CMS_PAGE } from '../../../util/Event';
import BaseEvent from './BaseEvent.event';
import ConvertQuery from '../../../query/Convert.query';
import { fetchQuery } from 'Util/Request/Query';

export const CMS_PAGE_EVENT_DELAY = 200;
export const SPAM_PROTECTION_DELAY = 2;

/**
 * On checkout
 */
export class CmsEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CMS_PAGE_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_CONVERT_CMS_PAGE, ({ page }) => {
            this.handle(page);
        });
    }

    /**
     * Handle
     */
    handler(page) {
        if (!page || this.spamProtection(SPAM_PROTECTION_DELAY, 'cmspage_' + page)) {
            return;
        }

        const event = {
            'event_name':  'cms',
            'page_id': page
        };
        fetchQuery(ConvertQuery.getCONVERTConversion(event)).then(
            (result) => {
                if (result && result.getConvertTagConversionEvent) {
                    this.updateLayer(result.getConvertTagConversionEvent);
                }
            }
        )
        .catch(
            (error) => {
                console.log('convertevent', 'error', error);
            }
        );
    }
}

export default CmsEvent;
