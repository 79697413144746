/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PinterestRouteWrapperComponent from './PinterestRouteWrapper.component';

export const withPINTEREST = (originalRender, pinterestRoute) => (
    <PinterestRouteWrapperComponent route={ pinterestRoute }>
        { originalRender }
    </PinterestRouteWrapperComponent>
);

export default withPINTEREST;
