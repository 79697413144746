/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_CONVERT_GENERAL_INIT, EVENT_CONVERT_META_UPDATE } from '../../../util/Event';
import { isEventEnabled } from '../../../util/EventConfig';
import BaseEvent from './BaseEvent.event';
import ConvertQuery from '../../../query/Convert.query';
import { fetchQuery } from 'Util/Request/Query';

export const GENERAL_EVENT_DELAY = 200;

/**
 * CONVERT PWA General event
 *
 * On: page load, page change location
 */
export class General extends BaseEvent {
    /**
     * If already loading data, do not do second request
     *
     * @type {boolean}
     */
    isLoadingData = false;

    /**
     * Set base event call delay
     *
     * @type {number}
     */
    eventHandleDelay = 0;

    /**
     * Current meta data
     *
     * @type {{}}
     */
    currentMeta = { title: '' };

    /**
     * Bind PWA event handling
     */
    bindEvent() {
        // Page load, wait a bit for better user performance
        /*setTimeout(() => {
            this.saveCartDataToStorage();
            this.handle();
        }, GENERAL_EVENT_DELAY);*/

        // eslint-disable-next-line prefer-destructuring
        const history = this.getCONVERT().props.history;

        // eslint-disable-next-line fp/no-let
        let prevLocation = history.location;

        history.listen((location) => { // On page change
            const { pathname } = location;
            const { pathname: prevPathname } = prevLocation;

            // prevents from firing general on filter change (PLP) and on attribute change (PDP)
            if (
                pathname === prevPathname
            ) {
                return;
            }


            const urlParts = pathname.split('/').filter(item => item.trim() !== "");
            if (urlParts.length <= 1) {
                return;
            }
            if(pathname.indexOf('/cart') !== -1 || pathname.indexOf('/checkout/') !== -1){
                return;
            }
            this.saveCartDataToStorage();
            prevLocation = location;

            setTimeout(() => {
                this.handle();
            }, GENERAL_EVENT_DELAY);
        });

        // Receive current meta
        Event.observer(EVENT_CONVERT_META_UPDATE, (meta) => {
            this.currentMeta = meta;
        });
    }

    saveCartDataToStorage() {
        const storage = this.getStorage() || {};
        storage.cartLast = storage.cart;
        storage.cart = this.getAppState().CartReducer.cartTotals.items;
        this.setStorage(storage);
    }

    /**
     * Handler General
     */
    handler() {
        if (!isEventEnabled(EVENT_CONVERT_GENERAL_INIT)) {
            return;
        }

        const event = {
            'event_name':  'page_visit'
        };

        fetchQuery(ConvertQuery.getCONVERTConversion(event)).then(
            (result) => {
                if (result && result.getConvertTagConversionEvent) {
                    this.updateLayer(result.getConvertTagConversionEvent);
                }
            }
        )
        .catch(
            (error) => {
                console.log('convertevent', 'error', error);
            }
        );
    }

    /**
     * Get current store view
     *
     * @return {string}
     */
    getStoreView() {
        return this.getAppState().ConfigReducer.code || '';
    }

    /**
     * @param {*} item
     */
    getQuantity({ qty }) {
        return qty;
    }

    /**
     * Get current language
     *
     * @return {string}
     */
    getLanguage() {
        return this.getAppState().ConfigReducer.locale || '';
    }

    /**
     * Get current signed in customer id
     *
     * @return {string}
     */
    getCustomerId() {
        return this.isSignedIn() ? this.getAppState().MyAccountReducer.customer.id || '' : '';
    }

    /**
     * Get customer city
     *
     * @return {string}
     */
    getCountryName() {
        return this.getAppState().ConfigReducer.default_country || '';
    }
}

export default General;
