/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, { EVENT_CONVERT_CHECKOUT } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import ConvertQuery from '../../../query/Convert.query';
import { fetchQuery } from 'Util/Request/Query';

export const CHECKOUT_EVENT_DELAY = 200;
export const SPAM_PROTECTION_DELAY = 200;

/**
 * On checkout
 */
export class CheckoutEvent extends BaseEvent {
    /**
     * Event fire delay
     *
     * @type {number}
     */
    eventHandleDelay = CHECKOUT_EVENT_DELAY;

    /**
     * Bind
     */
    bindEvent() {
        Event.observer(EVENT_CONVERT_CHECKOUT, ({ totals, step }) => {
            this.handle(totals, step);
        });
    }

    /**
     * Handle
     */
    handler(totals, step) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY,'checkout' + step)) {
            return;
        }

        const event = {
            'event_name':  step === 1 ? 'cart' : 'checkout'
        };
        fetchQuery(ConvertQuery.getCONVERTConversion(event)).then(
            (result) => {
                if (result && result.getConvertTagConversionEvent) {
                    this.updateLayer(result.getConvertTagConversionEvent);
                }
            }
        )
        .catch(
            (error) => {
                console.log('convertevent', 'error', error);
            }
        );
    }
}

export default CheckoutEvent;
