/* eslint-disable max-lines, simple-import-sort/sort */
import DocumentQuery from 'Query/Document.query';
import TrainingQuery from 'Query/Training.query';
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace PeggysagePwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    searchProducts({ query, pageSize, filters = [] }) {
        return new Field('products')
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('search', 'String', query)
            .addArgument('filter', 'ProductAttributeFilterInput', filters)
            .addFieldList(this._getProductFields());
    }

    getPerfectProductDetails({ pageSize, filters = [] }) {
        return new Field('products')
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('filter', 'ProductAttributeFilterInput', filters)
            .addFieldList(this._getPerfectProductDetailsField());
    }

    setOptions(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }
        this.options = options;
        return this;
    }

    /**
     * Override : add variation products
     * @param isVariant
     * @param isForLinkedProducts
     * @param isForWishlist
     * @returns {[string, string, string, string, string, string, string, *, *]}
     * @private
     */
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct
        } = this.options;

        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        if (isSingleProduct && (!(isPlp && isVariant) || isForWishlist)) {
            fields.push(
                this._getMediasListingField()
            );
        }

        if (!isVariant) {
            fields.push(
                'regroupement_produit',
                'variation_products_count',
                this._getTagsField(),
                'afficher_produit_reserve_pro',
                'produit_pro',
                'produit_etudiant',
                'attribute_set_id',
                TrainingQuery._getTrainingDatesField(),
                'duree_formation_jours',
                'duree_formation_heures',
                'pas_retrait_magasin',
                'contenance_label',
                'pastille_promo',
                'code_barre'
            );
        }

        if (isSingleProduct) {
            fields.push(
                this._getVariationProductsField(),
                this._getVariationSecondaireProductsField(),
                this._getRelatedTutorialsField(),
                this._getRelatedFaqField(),
                this._getRelatedDocumentsField(),
                'inci',
                'conseils_utilisation',
                'pour_qui',
                'precautions',
                'afficher_produit_reserve_pro',
                new Field('restricted_groups').setAlias('limitation_customer_group_ids'),
                this._getInfoTriField(),
                'qce',
                'actifs',
                'efficacite_prouvee',
                this._getFrontendAttributesField(),
                'product_type_for_rating',
                this._getCollectionTextField(),
                'product_suggestion_skus',
                'code_barre'
            );
        } else {
            fields.push('image_secondaire');
        }

        return fields;
    }

    _getPerfectProductDetailsField() {
        return [
            new Field('items')
                .addFieldList([
                    'uid',
                    'attribute_set_id',
                    'name',
                    'sku',
                    'perfect_widget'
                ])];
    }

    /**
     * For other thumbnails on listing (hover on desktop, slider on mobile)
     * @returns {Field}
     * @private
     */
    _getMediasListingField() {
        return new Field('media_gallery_entries')
            .addFieldList([
                new Field('base').addFieldList([
                    'url'
                ]),
                'disabled',
                'types'
            ]);
    }

    /**
     * Override: Add answer fields + customer group id
     * @returns {*[]}
     * @private
     */
    _getReviewItemsFields() {
        return [
            ...super._getReviewItemsFields(),
            'answer',
            'answer_created_at',
            'customer_group_id',
            'visible_default'
        ];
    }

    /**
     * Override: add fixed_product_taxes
     * @returns {*[]}
     * @private
     */
    _getMinimalPriceFields() {
        const { isSingleProduct } = this.options;

        if (isSingleProduct) {
            return [
                ...super._getMinimalPriceFields(),
                this._getFixedProductTaxesField()
            ];
        }

        return super._getMinimalPriceFields();
    }

    _getFixedProductTaxesField() {
        return new Field('fixed_product_taxes')
            .addFieldList([
                new Field('amount')
                    .addFieldList([
                        'value',
                        'currency'
                    ]),
                'label'
            ]);
    }

    _getVariationColorsFields() {
        return [
            'value',
            'label'
        ];
    }

    _getVariationColorsField() {
        return new Field('colors')
            .addFieldList(this._getVariationColorsFields());
    }

    _getVariationProductsFields() {
        return [
            'id',
            'name',
            'sku',
            'url_key',
            'stock_status',
            'perfect_widget',
            this._getVariationColorsField(),
            'variation_thumbnail_url'
        ];
    }

    _getVariationProductsField() {
        return new Field('variation_products')
            .addFieldList(this._getVariationProductsFields());
    }

    _getVariationSecondaireProductsField() {
        return new Field('variation_secondaire_products')
            .addFieldList(this._getVariationProductsFields());
    }

    _getRelatedTutorialsFields() {
        return [
            'identifier',
            'title',
            'content_image'
        ];
    }

    _getRelatedTutorialsField() {
        return new Field('related_tutorials')
            .addFieldList(this._getRelatedTutorialsFields());
    }

    _getRelatedFaqFields() {
        return [
            'faq_id',
            'name',
            'content'
        ];
    }

    _getRelatedFaqField() {
        return new Field('related_faqs')
            .addFieldList(this._getRelatedFaqFields());
    }

    _getRelatedDocumentsField() {
        return new Field('documents')
            .addFieldList(DocumentQuery._getDocumentsFields());
    }

    _getTagsField() {
        return new Field('tags')
            .addFieldList([
                'type',
                'value'
            ]);
    }

    _getInfoTriField() {
        return new Field('info_tri')
            .addFieldList([
                'code',
                'logo',
                'text',
                'extra_text'
            ]);
    }

    _getFrontendAttributesField() {
        return new Field('frontend_attributes')
            .addFieldList([
                'attribute_code',
                'attribute_value',
                'attribute_label'
            ]);
    }

    _getCollectionTextField() {
        return new Field('collection_text')
            .addFieldList([
                'title',
                'description'
            ]);
    }

    /**
     * Override: add image to filters options
     * @returns {(string|*|string)[]}
     * @private
     */
    _getAggregationsOptionsFields() {
        return [
            ...super._getAggregationsOptionsFields(),
            'image',
            'category_url'
        ];
    }

    /**
     * Override: add product top category + color for bundle options
     * @returns {(string|*|Field)[]}
     * @private
     */
    _getProductBundleOptionFields() {
        const { isSingleProduct } = this.options;
        if (isSingleProduct) {
            return [
                ...super._getProductBundleOptionFields(),
                'uid',
                'id',
                'sku',
                'type_id',
                'url',
                'salable_qty',
                this._getProductThumbnailField(),
                this._getStockItemFields,
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                'inci',
                this._getProductBundleOptionTopCategoryField(),
                this._getVariationColorsField(),
                'image_secondaire',
                'pastille_couleur',
                this._getVariationTextureField()
            ];
        }

        return ['name'];
    }

    _getProductBundleOptionTopCategoryField() {
        return new Field('top_category')
            .addFieldList([
                'uid',
                'id',
                'name'
            ]);
    }

    _getVariationTextureField() {
        return new Field('texture_attribute_value')
            .addFieldList([
                'value',
                'label'
            ]);
    }

    /**
     * For retreiving color image
     * @returns {Field}
     * @private
     */
    _getProductBundleOptionMediasListingField() {
        return new Field('media_gallery_entries')
            .addFieldList([
                new Field('thumbnail').addFieldList([
                    'url'
                ]),
                'types'
            ]);
    }

    /**
     * Override: set isCart to false for Attributes field to have attribute_type,
     * add variations
     * @returns {(string|*|Field)[]}
     * @private
     */
    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            // this._getAttributesField(false, false),
            this._getProductLinksField(),
            'url_key',
            'variation_thumbnail_url',
            'regroupement_produit',
            'pas_retrait_magasin',
            'code_barre'
        ];
    }

    _getBundleProductFragmentFields() {
        const { isSingleProduct } = this.options;
        if (isSingleProduct) {
            return [
                'dynamic_price',
                'dynamic_sku',
                'ship_bundle_items',
                'dynamic_weight',
                this._getBundleItemsField(),
                this._getBundlePriceOptionsField()
            ];
        }

        return [
            'dynamic_price',
            'dynamic_sku',
            'ship_bundle_items',
            'dynamic_weight',
            this._getBundlePriceOptionsField(true)
        ];
    }

    _getAttributesField(isVariant, isCart) {
        const { isSingleProduct, isForWishlist: isForWishlistFromOptions } = this.options;
        if (isCart || isSingleProduct || isForWishlistFromOptions) {
            return new Field('s_attributes')
                .setAlias('attributes')
                .addFieldList(this._getAttributeFields(isVariant, isCart));
        }

        return null;
    }

    /**
     * Override: add parameter to prevent requesting bundle options
     * @param noDetails
     * @return {(string|Field)[]|string[]}
     * @private
     */
    _getBundlePriceOptionFields(noDetails = false) {
        if (noDetails) {
            return [
                'option_id'
            ];
        }

        return [
            'option_id',
            new Field('selection_details')
                .addFieldList(this._getBundlePriceOptionSelectionFields())
        ];
    }

    /**
     * Override: add parameter to prevent requesting bundle options
     * @param noDetails
     * @return {Field}
     * @private
     */
    _getBundlePriceOptionsField(noDetails = false) {
        return new Field('bundle_options')
            .addFieldList(this._getBundlePriceOptionFields(noDetails));
    }

    // start remove review fields
    _getReviewCountField() {
        return null;
    }

    _getRatingSummaryField() {
        return null;
    }

    _getReviewsField() {
        return null;
    }
    // end remove review fields
}

export default new ProductListQuery();
