/* eslint-disable import/no-cycle */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';

import Event, { EVENT_PINTEREST_PURCHASE } from '../../../util/Event';
import ProductHelper from '../utils';
import BaseEvent from './BaseEvent.event';
import PinterestQuery from '../../../query/Pinterest.query';
import { fetchQuery } from 'Util/Request/Query';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 */
export class Purchase extends BaseEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_PINTEREST_PURCHASE, ({ orderId, increment_id, totals, customer_email}) => {
            this.handle(
                orderId,
                increment_id,
                totals,
                customer_email
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, increment_id, totals, customer_email) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY,`purchase_${ orderId }`)) {
            return;
        }

        const event = {
            'event_name':  'checkout',
            'order_id': orderId,
            'increment_id': increment_id,
            'customer_email': customer_email
        };

        fetchQuery(PinterestQuery.getPINTERESTConversion(event)).then(
            (result) => {
                if (result && result.getPinterestConversionEvent && result.getPinterestConversionEvent.event_id && result.getPinterestConversionEvent.order_quantity) {
                    try {
                        pintrk('track', 'checkout', {
                            event_id: result.getPinterestConversionEvent.event_id,
                            line_items: result.getPinterestConversionEvent.line_items,
                            currency: result.getPinterestConversionEvent.currency,
                            value: result.getPinterestConversionEvent.value,
                            order_id: result.getPinterestConversionEvent.order_id,
                            order_quantity: result.getPinterestConversionEvent.order_quantity,
                        });
                    } catch (e){
                        console.log('pinterestevent', 'error', e);
                    }
                }
            }
        )
            .catch(
                (error) => {
                    console.log('pinterestevent', 'error', error);
                }
            );


    }

}

export default Purchase;
