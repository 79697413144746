/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Event, {
    EVENT_CONVERT_PRODUCT_ADD_TO_CART
} from '../../util/Event';

export const aroundAfterAddToCartConvert = (args, callback, instance) => {

    const { product, product: { attributes, type_id } } = instance.props;
    const { quantity } = instance.state;
    if (type_id !== 'grouped') {
        Event.dispatch(EVENT_CONVERT_PRODUCT_ADD_TO_CART, {
            product,
            quantity
        });
    }

    return callback(...args);
};

export default {
    'PeggysagePwa/Component/Product/Container': {
        'member-function': {
            addToCart: aroundAfterAddToCartConvert
        }
    }
};

