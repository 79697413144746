/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


import { CMS_PAGE_EVENT_DELAY } from '../../component/Convert/events/Cms.event';
import Event, {
    EVENT_CONVERT_CMS_PAGE
} from '../../util/Event';



export const componentDidMount = (args, callback, instance) => {
    return callback(...args);
};

export const componentDidUpdate = (args, callback, instance) => {
    const { pageIds: page } = instance.props;
    const { isLoading } = instance.state;

    if (page && !isLoading) {
        Event.dispatch(EVENT_CONVERT_CMS_PAGE, {
            page
        });
    }

    return callback(...args);
};

export default {
    'Route/CmsPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};
