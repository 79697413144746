/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class ConvertQuery {
    _getCONVERTConfigurationFields = () => ([
        'enabled',
        'script_url',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'convert_general_init',
        'convert_impressions',
        'convert_product_detail',
        'convert_product_add_to_cart',
        'convert_purchase',
        'convert_search',
        'convert_checkout',
        'convert_cms',
        'convert_homepage'
    ]);

    _getCONVERTConversionEventFields = () => ([
        '_conv_page_type',
        '_conv_category_id',
        '_conv_category_name',
        '_conv_product_sku',
        '_conv_product_name',
        '_conv_product_price',
        '_conv_customer_id',
        '_conv_custom_v1',
        '_conv_custom_v2',
        '_conv_custom_v3',
        '_conv_custom_v4',

    ]);



    getCONVERTConfiguration = () => new Field('getConvertTag')
        .setAlias('convert')
        .addFieldList(this._getCONVERTConfigurationFields());

    getCONVERTConversion = (event) => new Field('getConvertTagConversionEvent')
        .addArgument('event', 'ConvertConversionEventInput!', event)
        .addFieldList(this._getCONVERTConversionEventFields());
}

export default new ConvertQuery();
