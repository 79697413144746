/* eslint-disable simple-import-sort/sort */
import {
    VALIDATION_INPUT_TYPE_TEXT as SOURCE_VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_INPUT as SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    // VALIDATION_INPUT_TYPE as SOURCE_VALIDATION_INPUT_TYPE,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES
} from 'SourceUtil/Validator/Config';

export {
    VALIDATION_INPUT_TYPE_NUMBER
};

export const VALIDATION_INPUT_TYPE_TEXT = {
    ...SOURCE_VALIDATION_INPUT_TYPE_TEXT,
    alphaSpaceDash: 'alphaSpaceDash',
    noSemiColon: 'noSemiColon'
};

export const VALIDATION_INPUT_TYPE_INPUT = {
    ...SOURCE_VALIDATION_INPUT_TYPE_INPUT,
    taxvatBe: 'taxvatBe',
    taxvatEs: 'taxvatEs'
};

export const VALIDATION_INPUT_TYPE = {
    ...VALIDATION_INPUT_TYPE_TEXT,
    ...VALIDATION_INPUT_TYPE_NUMBER,
    ...VALIDATION_INPUT_TYPE_INPUT
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.alphaSpaceDash]: __('Ce champ contient des caractères erronés.'),
    [VALIDATION_INPUT_TYPE.noSemiColon]: __('Ce champ contient des caractères erronés.'),
    [VALIDATION_INPUT_TYPE.taxvatBe]: __('Ce champ doit être au format BEXXXXXXXXXX'),
    [VALIDATION_INPUT_TYPE.taxvatEs]: __('Incorrect input!')
};

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    // Accept accentuated letters, dash and single quote in names (O'Neil...)
    [VALIDATION_INPUT_TYPE.alphaSpaceDash]: /^[\p{Letter}\s\\'-]+$/igu,
    [VALIDATION_INPUT_TYPE.noSemiColon]: /^[^;]*$/igu,
    // BE9999999999
    [VALIDATION_INPUT_TYPE.taxvatBe]: /^BE[0-9]{10}$/,
    // ESX9999999X (X can be alpha or num, but cannot be both numeric)
    [VALIDATION_INPUT_TYPE.taxvatEs]: /^ES(([a-z]{1}[0-9]{7}[0-9]{1})|([0-9]{1}[0-9]{7}[a-z]{1})|([a-z]{1}[0-9]{7}[a-z]{1}))$/i
};
